h1 {
    text-align: center;
}

@media screen and (max-width: 40em) {
    h1 {
        padding-top: 1em;
        font-size: 1.5em !important;
    }
    h5 {
        font-size: 0.75em !important;
    }
    .bm-burger-button {
        width: 30px !important;
        height: 24px !important;
        left: 30px !important;
        top: 30px !important;
    }
    .refresh-expressions {
        width: 30px !important;
        height: 24px !important;
        right: 30px !important;
        top: 30px !important;
    }
}

main {
    height: 100%;
    text-align: center;
    overflow: auto;
}

.refresh-expressions {
    z-index: 100;
    margin: 0px;
    padding: 0px;
    border: none;
    font-size: 0px;
    background: transparent;
    cursor: pointer;
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
}

.refresh-expressions svg {
    width: 100%;
    height: 100%;
}

footer {
    margin-top: 1em;
    padding: 1em;
    border-top: 1px solid black;
}