.expression {
    margin-bottom: 0.5em;
}

.answer{
    text-align: center;
}

.expression [class*="col"]{
    padding: 0px;
    line-height: 2em;
}

.expression input{
    max-width: 6em;
    display: inline-block;
}